.moduleWrapper {
  height: 100vh;

  :global {
    .ant-form-item .ant-form-item-label > label {
      height: auto;
    }

    .active span > svg path {
      stroke: #e4a45c;
    }

    .active svg {
      color: #e4a45c !important;
    }
  }
}

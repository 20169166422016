.moduleWrapper {
  display: flex;

  :global {
    .mainButton {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      border-radius: 5px;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

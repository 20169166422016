//Reset
html,
body {
  margin: 0;
  padding: 0;
  font-family:
    Libre Franklin,
    serif;
}

@import './reset.css';

// SCSS vars
$color-000000: '#000000';
$color-E2E2E2: '#E2E2E2';
$color-5A5A5A: '#5A5A5A';
$color-7D7D7A: '#7D7D7A';
$color-9D9D9D: '#9D9D9D';
$color-B4B4B4: '#B4B4B4';
$color-FAFAFA: '#FAFAFA';
$color-FFFFFF: '#FFFFFF';
$color-7E38B7: '#7E38B7';
$color-8466C7: '#8466C7';
$color-BB86FC: '#BB86FC';
$color-DDD7F5: '#DDD7F5';
$color-F6EAFF: '#F6EAFF';
$color-008080: '#008080';
$color-009393: '#009393';
$color-03DAC5: '#03DAC5';
$color-A8E6DF: '#A8E6DF';
$color-CDEEED: '#CDEEED';
$color-009393: '#009393';
$color-03DAC5: '#03DAC5';
$color-A8E6DF: '#A8E6DF';
$color-CDEEED: '#CDEEED';
$color-F0AE49: '#F0AE49';
$color-0E8345: '#0E8345';
$color-A8E6DF: '#A8E6DF';
$color-DE1135: '#DE1135';
$color-F6BC2F: '#F6BC2F';
$color-E6F7F6: '#E6F7F6';

// Global native css vars
:root {
  --color-000000: $color-000000;
  --color-E2E2E2: $color-E2E2E2;
  --color-5A5A5A: $color-5A5A5A;
  --color-7D7D7A: $color-7D7D7A;
  --color-9D9D9D: $color-9D9D9D;
  --color-B4B4B4: $color-B4B4B4;
  --color-FAFAFA: $color-FAFAFA;
  --color-FFFFFF: $color-FFFFFF;
  --color-7E38B7: $color-7E38B7;
  --color-8466C7: $color-8466C7;
  --color-BB86FC: $color-BB86FC;
  --color-DDD7F5: $color-DDD7F5;
  --color-F6EAFF: $color-F6EAFF;
  --color-008080: $color-008080;
  --color-009393: $color-009393;
  --color-03DAC5: $color-03DAC5;
  --color-A8E6DF: $color-A8E6DF;
  --color-CDEEED: $color-CDEEED;
  --color-F0AE49: $color-F0AE49;
  --color-0E8345: $color-0E8345;
  --color-DE1135: $color-DE1135;
  --color-F6BC2F: $color-F6BC2F;
  --color-E6F7F6: $color-E6F7F6;
}

//Tailwinds
@tailwind base;
@tailwind components;
@tailwind utilities;

//Custom scrollbar
/* Width and Color of the scrollbar track */
::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(241, 241, 241, 0.56);
}

/* Color and style of the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: rgba(201, 201, 201, 0.58);
  border-radius: 0;
}

/* On hover, the scrollbar thumb will be a little darker */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(201, 201, 201, 0.54);
}

//Global fonts

.displayLarge {
  font-family: Poppins, serif;
  font-size: 68px;
  font-style: normal;
  font-weight: 500;
  line-height: 120px; /* 176.471% */
}

.displayMedium {
  font-family: Poppins, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px; /* 112.5% */
}

.displaySmall {
  font-family: Poppins, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 127.778% */
  letter-spacing: 0.25px;
}

.titleExtraLarge {
  font-family:
    Libre Franklin,
    serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.titleLarge {
  font-family:
    Libre Franklin,
    serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.titleMedium {
  font-family:
    Libre Franklin,
    serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 133.333% */
}

.titleSmall {
  font-family:
    Libre Franklin,
    serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.bodyExtraLarge {
  font-family:
    Libre Franklin,
    serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
}

.bodyLarge {
  font-family:
    Libre Franklin,
    serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
}

.bodyMedium {
  font-family:
    Libre Franklin,
    serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.035px;
}

.bodySmall {
  font-family:
    Libre Franklin,
    serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.3px;
}

.labelExtraLarge {
  font-family: Poppins, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.5px;
}

.labelLarge {
  font-family: Poppins, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
}

.labelMedium {
  font-family: Poppins, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
  letter-spacing: 0.15px;
}

.labelSmall {
  font-family: Poppins, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.15px;
}

//Fix checkbox ant design
.ant-table-wrapper .ant-table-selection-column {
  padding-inline-start: 14px !important;
}

//Fix popover radius
.ant-popover-inner {
  border-radius: 4px !important;
}

//Fix ant table sort icon
.ant-table-wrapper .ant-table-column-sorters {
  justify-content: flex-start !important;
  width: fit-content !important;
}

//Disabled div
[aria-disabled='true'] {
  opacity: 0.5;
  cursor: not-allowed;
}

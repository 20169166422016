.moduleWrapper {
  :global {
    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after,
    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
      color: #fff;
      font-size: 35px;
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
      cursor: pointer;
      background: #fff;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: #e4a45c !important;
    }
  }
}

.moduleWrapper {
  :global {
    @media screen and (max-width: 768px) {
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .pricingHeading {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }
}

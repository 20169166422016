.moduleWrapper {
  width: 100%;

  :global {
    .ant-form-vertical .ant-form-item-label {
      //padding-bottom: 0 !important;
    }

    .input:not([type='button']):not([type='submit']),
    textarea {
      padding: 7px 11px !important;
      border-radius: 7px !important;
    }

    // Datepicker
    .ant-picker {
      border-radius: 4px 4px 4px 4px !important;
      padding: 10px 10px 10px 10px !important;
    }

    //Number
    .ant-select-single,
    .ant-input-number-input {
      height: 44px !important;
    }

    .ant-input-number-lg,
    .ant-select-selector {
      border-radius: 4px 4px !important;
    }

    .ant-picker .ant-picker-input > input:placeholder-shown {
      padding: 0 0 !important;
    }

    .ant-picker-input input {
      padding: 0 0 !important;
    }

    .ant-form-large .ant-form-item .ant-form-item-label > label {
      height: auto !important;
    }

    .ant-form-item .ant-form-item-label > label {
      align-items: flex-start;
    }
  }
}

:global {
  body[sidebar='true'] {
    .main {
      .sidebar {
        width: 81px !important;
      }

      .pageContent {
        width: calc(100% - 81px) !important;
      }
    }
  }
}

.moduleWrapper {
  :global {
    .wrapper {
      .header {
      }

      .footer {
      }

      .main {
        height: calc(100vh - 65px * 2);
        background: #edebeb;
        display: flex;
        flex-direction: row;

        .sidebar {
          border-right: 1px solid #d9d9d9;
          width: 208px;
          background: #fff;
        }

        .pageContent {
          height: calc(100vh - 130px);
          width: calc(100% - 208px);
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          overflow-y: scroll;

          .centeredContent {
            height: 100%;
            //width: 1300px;
            width: 100%;
            margin: 0 auto;
            padding: 30px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

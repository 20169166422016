//No responsive
:global {
}

.moduleWrapper {
  :global {
    .ant-select-selector {
      background-color: #f5f5f5 !important;
      border: none !important;
    }
  }
}

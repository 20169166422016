.moduleWrapper {
  :global {
    .header {
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-bottom: 1px solid #d9d9d9;
      box-shadow: 2px 9px 3px 4px #fbfbfb;
    }

    .userInfo {
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;
      align-items: center;
    }

    .logo {
      display: flex;
    }

    .center {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      line-height: 20px;
    }
  }
}

.moduleWrapper {
  :global {
    .scrollable-list {
      height: 70%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: auto;
      scrollbar-width: none; /* For Firefox */
    }

    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
  }
}

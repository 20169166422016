.moduleWrapper {
  :global {
    .footer {
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-000000);
      border-top: 1px solid #d9d9d9;
    }
  }
}

.moduleWrapper {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;

  :global {
    .footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--color-000000);
      border-top: 1px solid #d9d9d9;

      .box {
        width: 100%;
        padding-left: 25px;
        padding-right: 25px;
        max-width: 1320px;
      }
    }
  }
}

//No responsive
:global {
  body:not(.page):not(.post) {
    //min-width: 1440px;
    overflow-x: auto;
  }
}

.moduleWrapper {
  :global {
    .grayInput {
      border: none !important;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #f5f5f5 !important;

      // Fix for password field
      [type='password'] {
        background: transparent;
      }
    }
  }
}
